<template>
  <div
    class="tag"
    :class="{
      'tag--small': small,
      'tag--large': large,
      'tag--no-margin': noMargin,
      'tag--green': green,
      'tag--red': red,
      'tag--black': black,
      'tag--white': white,
      'tag--blue': blue,
      'tag--light': light,
    }"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
const store = useDefaultStore();
const shopwareStore = useShopwareStore();

const props = defineProps({
  small: {
    type: Boolean,
    default: false,
  },
  large: {
    type: Boolean,
    default: false,
  },
  noMargin: {
    type: Boolean,
    default: false,
  },
  green: {
    type: Boolean,
    default: false,
  },
  red: {
    type: Boolean,
    default: false,
  },
  black: {
    type: Boolean,
    default: false,
  },
  white: {
    type: Boolean,
    default: false,
  },
  blue: {
    type: Boolean,
    default: false,
  },
  light: {
    type: Boolean,
    default: false,
  },
});
</script>

<style lang="scss">
.tag {
  font-size: $text-sm;
  background: $color-light-gray;
  border-radius: $radius-large;
  // margin-bottom: $spacing-xs;
  // margin-right: $spacing-xs;
  padding: $spacing-xxs $spacing-xs;
  display: inline-block;
  color: $color-dark-gray;
  line-height: 1;
  // white-space: nowrap;
  font-weight: $font-bold;

  &--no-margin {
    margin: 0;
  }

  &--light {
    font-weight: $font-normal;
  }

  &--small {
    font-size: $text-xs;
    padding: $spacing-xxs;
  }

  &--large {
    padding: $spacing-xs $spacing-sm;
    font-size: $text-md;
  }

  &--green {
    background: $color-green;
    color: $color-white;
  }

  &--red {
    background: $color-red;
    color: $color-white;
  }

  &--black {
    background: $color-black;
    color: $color-white;
  }
  &--white {
    background: $color-white;
    color: $color-black;
  }
  &--blue {
    background: $color-blue;
    color: $color-white;
  }
}
</style>
